// Libs.
import { isEmpty } from 'lodash';
import crmService from '../../services/crmClientService';
import { CardRemove, CardLoaded, AddNewCard, ReplaceCard } from './action-creators';
import {addNotification} from "../../libs/notify";

// Fetch card balance.
export const FetchCardLoad = async (customerId, userToken, dispatchCallback) => {
  try {
    const cardAndPoints = await crmService.tyrCardPointsGet(customerId, userToken);
    if (cardAndPoints && !isEmpty(cardAndPoints)) {
      dispatchCallback(CardLoaded(cardAndPoints));
    }
  } catch (e) {
    if (e.ResponseCode === '201') {
      dispatchCallback(CardRemove(''));
    }
  }
};

// Add card to user account.
export const AddCardNumber = async (customerId, CardNumber, cardData, userToken, dispatchCallback) => {
  try {
    const cardSave = await crmService.tryCardLink(customerId, CardNumber, cardData, userToken);
    if (!!cardSave.ResponseCode && cardSave.ResponseCode === '000') {
      dispatchCallback(AddNewCard(CardNumber));
    }
    return cardSave;
  } catch (e) {
    console.log(e);
    if (e.ResponseCode === '303') {
      throw e;
    } else {
      addNotification(e.message, 'error');
    }
  }
};

// Replace card in user's account.
export const ReplaceCardNumber = async (customerId, CardNumber, cardData, userToken, dispatchCallback) => {
  try {
    const cardSave = await crmService.tryCardTransfer(customerId, CardNumber, cardData, userToken);
    if (!!cardSave.ResponseCode && cardSave.ResponseCode === '000') {
      dispatchCallback(ReplaceCard(CardNumber));
    }
    return cardSave;
  } catch (e) {
    if (e.ResponseCode === '202') {
      dispatchCallback(CardRemove(''));
    }

    if (e.ResponseCode === '303') {
      dispatchCallback(CardRemove(''));
      throw e;
    }
    
    return true;
  }
};

export const AddMissingPoints = async (
  customerId,
  transactionId,
  transactionDate,
  transactionAmount,
  userToken
) => {
  try {
    const transaction = {
      TransactionNumber: transactionId,
      TransactionDate: transactionDate,
      TransactionAmount: transactionAmount,
    };

    const missingtrAnsactions = await crmService.transactionAttach(
      customerId,
      transaction,
      userToken
    );
    return missingtrAnsactions;
  } catch (e) {
    return e;
  }
};
