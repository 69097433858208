// Libs.
import React, { useContext } from 'react';
import { partial } from 'lodash';
import { useAsync } from 'react-async-hook';
// Components.
import DownloadMobappCTA from '../common/download-mobapp-cta';
import TyrCardboxMobile from '../tyr-card/tyr-cardbox-mobile';
import TyrCardboxDesktop from '../tyr-card/tyr-cardbox-desktop';
import AddReplaceCards from './customer-add-replace-cards';
import PagePreloader from '../common/loaders/page-preloader';
import { ProfileMenuBack } from '../layout/member-layout';
// Custom Libs.
import { redirectKeyPressHandler } from '../../libs/accessibility';
// Context.
import { AppContext } from '../layout/AppLayout';
// State.
import { useStateValue } from '../../store/state';
import { OpenModal } from '../../store/modal/action-creators';
import { modalTypes } from '../../store/modal/action-types';
// Store.
import { FetchCardLoad } from '../../store/customer/actions';
import useCustomer from '../../hooks/useCustomer';
// Assets.
import styles from './customer-tyr-cards.module.scss';
import locale from '../../locale/locale';
import AppHidden from '../app/common/app-hidden';
import AppShown from '../app/common/app-shown';

const CustomerTyrCards = () => {
  const [{ user, customer }, dispatch] = useStateValue();
  const { getCustomer } = useCustomer();
  const app = useContext(AppContext);

  const toggle = (_action) => {
    dispatch(
      OpenModal({
        type: modalTypes.SLIDEIN,
        content: <AddReplaceCards action={_action} />,
      })
    );
  };

  const handleToggleAdd = partial(toggle, 'add');
  const handleToggleReplace = partial(toggle, 'replace');
  const fetchCustomerAndCard = async () => {
    if (!customer.CardNumber) {
      await getCustomer();
    }

    if (user.access_token) {
      await FetchCardLoad(user.CRMCustomerID, user.access_token, dispatch);
    }
  };

  const asyncData = useAsync(fetchCustomerAndCard, [
    user.CRMCustomerID,
    user.access_token,
    customer.CardNumber,
  ]);

  if (asyncData.loading) {
    return <PagePreloader />;
  }

  return (
    <div className={styles.customerTyrCards} data-app={app}>
      <AppHidden>
        <ProfileMenuBack title="Your Card" />
      </AppHidden>
      <div className={styles.customerTyrCardsContainer}>
        <AppHidden>
          <h2 className={styles.customerTyrCardsTitle}>Your Card</h2>
        </AppHidden>
        <AppShown>
          <h2 className={styles.customerTyrCardsTitle}>Card</h2>
          <p>Present this card at checkout to earn points!</p>
        </AppShown>
        {customer.CardNumber === '' ? (
          <div className={styles.customerTyrCardsEmpty}>
            <div
              dangerouslySetInnerHTML={{
                __html: locale.pages.accountCards.missingCardNotice,
              }}
            ></div>
            <div
              role="button"
              tabIndex="0"
              className={styles.customerTyrCardsAddLostCard}
              onClick={handleToggleAdd}
              onKeyPress={redirectKeyPressHandler(handleToggleAdd)}
            >
              {locale.pages.accountCards.addNewCardButton}
            </div>
          </div>
        ) : (
          <div>
            <TyrCardboxDesktop customer={customer} />
            <TyrCardboxMobile customer={customer} />
            <div className={styles.customerTyrCardsLostCard}>
              <div
                dangerouslySetInnerHTML={{
                  __html: locale.pages.accountCards.lostCardNotice,
                }}
              ></div>

              <button
                tabIndex="0"
                className={styles.customerTyrCardsAddLostCard}
                onClick={handleToggleReplace}
                onKeyPress={redirectKeyPressHandler(handleToggleReplace)}
              >
                {locale.pages.accountCards.addReplacementCardButton}
              </button>
            </div>
          </div>
        )}
        <DownloadMobappCTA />
      </div>
    </div>
  );
};

export default CustomerTyrCards;
