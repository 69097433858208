import React from 'react';
import styles from './contact-brown-card.module.scss'

const ContactBrownCard = ({card}) => {
    return (
        <div className={styles.contactBrownCardContainer}>
            <div className={styles.contactBrownCardLineOne} dangerouslySetInnerHTML={{ __html: card }}/>
        </div>
    );
}
export default ContactBrownCard;
