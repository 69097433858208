// Deps.
import React, { useContext } from 'react';
import { uniqueId } from 'lodash';
// Components.
import StaticContentList from '../../components/common/static-content/static-content-list';
// State.
import { useStateValue } from '../../store/state';
import { CloseModal } from '../../store/modal/action-creators';
// Custom Libs.
import { redirectKeyPressHandler } from '../../libs/accessibility';
import {
    getCategorizedContentByTitle
} from '../../libs/static-content/static-content-utils';
// Assets.
import styles from './app-privacy-policies.module.scss';
import policies from '../../data/app-privacy-policies.json';
import locale from '../../locale/locale.json';
import { AppContext } from '../layout/AppLayout';
import {usePoliciesQuery} from "../../hooks/usePoliciesQuery";
import cardStyle from "../common/static-content/static-content-card.module.scss";

const AppPrivacyPolicies = () => {
  const {configPagesPoliciesApp: data } = usePoliciesQuery();
  const {title, body, subtitle} = data;
  console.log(title, body, subtitle)
  const isApp = useContext(AppContext);
  const content = getCategorizedContentByTitle(policies);
  const contentKeys = Object.keys(content);
  const [, dispatch] = useStateValue();
  const closeModal = () => {
    dispatch(CloseModal());
  };

  return (
    <div className={styles.privacyPoliciesContainer}>
      <div className={styles.privacyPoliciesBody}>
        <div
          role="button"
          tabIndex="0"
          onClick={closeModal}
          onKeyPress={redirectKeyPressHandler(closeModal)}
          className={styles.privacyPoliciesCloseIcon}
          data-app={isApp}
        />
        <div className={styles.privacyPoliciesTitle} >{title}</div>
        <div className={cardStyle.staticContentCardTextBody} dangerouslySetInnerHTML={{__html: subtitle}}/>
        <div className={cardStyle.staticContentCardTextBody} dangerouslySetInnerHTML={{__html: body}}/>
        <buttonx
          onClick={closeModal}
          onKeyPress={redirectKeyPressHandler(closeModal)}
          className={styles.privacyPoliciesCloseButton}
        >
          Close
        </buttonx>
      </div>
    </div>
  );
};

export default AppPrivacyPolicies;
