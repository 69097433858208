import { useStateValue } from '../store/state';
import navigate from './navigate';

const useAuthUserCheckOrError = () => {
  const [state] = useStateValue();

  if (!state.user.access_token) {
    // navigate('/403');
    navigate('/');
  }
};

export { useAuthUserCheckOrError };
