// Libs
import * as PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';

// State
import crmService from '../../services/crmClientService';
import formValidators from '../../libs/form/form-validator';
// Deps
import FormInput from './form-input';
// Assets
import styles from './tyrCardPinInput.module.scss';
import locale from '../../locale/locale';
import { useStateValue } from '../../store/state';
import Tooltip from '@material-ui/core/Tooltip';
import { AppContext } from '../layout/AppLayout';
import getWindow from '../../libs/getWindow';
import PINCard from '../../images/PINCard.png';

export const TYR_CARD_FOR_PIN = 45180000000;

const useStylesBootstrap = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'transparent'
  },
}));

const TyrCardPinInput = ({
                        containerClassName,
                        className,
                        label,
                        showCta,
                        showLabel,
                        allowCurrentUserCardNum,
                        placeholder,
                        state: local,
                        ctaPosition,
                      }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const classes = useStylesBootstrap();

  const isApp = useContext(AppContext);

  const checkValidCard = debounce(() => {
    // If the current card is valid
    if (!local.cardNumber.get || !local.cardNumberValid.get) {
      setDisabled(true);
      local.valid.set(true);
      local.error.set(false);
      local.value.set('');
    }

    // If the current card is valid and starts with 4518
    else if (parseInt(local.cardNumber.get) > TYR_CARD_FOR_PIN && local.cardNumberValid.get) {
      setDisabled(false);
      local.valid.set(false);
      local.error.set(false);
    } else {
      local.value.set('');
      local.valid.set(true);
      local.error.set(false);
      setDisabled(true);
    }


  }, 500);

  const checkValidPin = debounce(() => {
    if (local.value.get && /^\d{4}$/.test(local.value.get)) {
          local.valid.set(true);
          local.error.set(false);
    }
    // If we're in some other state, assume the card is not valid.
    else {
      local.valid.set(false);
    }
  }, 200);


  useEffect(() => checkValidPin(), [local.value.get]);

  useEffect(() => checkValidCard(), [local.cardNumber.get, local.cardNumberValid.get]);

  return (
    <div className={`${styles.tyrCardPinInput} ${containerClassName}`} data-label={!!label}>
      {showCta && (
        <div className={styles.tyrCardPinInputCta}>
          <div className={styles.tyrCardPinInputCtaLabel}>
            {showLabel && locale.form.tyrNumberPin.label}
          </div>
          <div className={styles.tyrCardPinInputCtaButton}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              classes={classes}
              onClose={() => setTooltipOpen(false)}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement={ctaPosition}
              title={<>
                <img src={PINCard} alt={locale.form.tyrNumberPin.label}/>
              </>}
            >
              <span
                onClick={(e) => { isApp || (!!getWindow('innerWidth') && getWindow('innerWidth') < 768 ) ? setTooltipOpen(!tooltipOpen) : e.preventDefault() }}
                onMouseOver={(e) => { !isApp ? setTooltipOpen(true) : e.preventDefault() }}
                onFocus={(e) => { !isApp ? setTooltipOpen(true) : e.preventDefault() }}
                onMouseOut={(e) => { !isApp ? setTooltipOpen(false) : e.preventDefault() }}
                onBlur={(e) => { !isApp ? setTooltipOpen(false) : e.preventDefault() }}
              >
                {locale.form.whatsThis}
              </span>
            </Tooltip>
          </div>
        </div>
      )}
      <FormInput
        className={className}
        state={local}
        disabled={disabled}
        validator={formValidators.tyrNumberRaw}
        placeholder={placeholder || locale.form.tyrNumberPin.label}
        errorMsg={
          !local.error.get && local.value.get && local.value.get.length === 11
            ? locale.form.tyrNumberPin.errorCard
            : locale.form.tyrNumberPin.error
        }
      />
    </div>
  );
};

TyrCardPinInput.defaultProps = {
  showCta: true,
  showLabel: false,
  state: null,
  ctaPosition: 'right',
  allowCurrentUserCardNum: true,
};

TyrCardPinInput.propTypes = {
  showCta: PropTypes.bool,
  showLabel: PropTypes.bool,
  ctaPosition: PropTypes.string,
  state: PropTypes.shape({
    cardNumber: PropTypes.object.isRequired,
    cardNumberValid: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    valid: PropTypes.object.isRequired,
  }).isRequired,
  allowCurrentUserCardNum: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TyrCardPinInput;
