// Libs
import React, { useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import JsBarcode from 'jsbarcode';
// Assets
import styles from './tyr-card-barcode.module.scss';

const TyrCardBarcode = ({ CardNumber }) => {
  const canvasRef = useRef();
  // console.log(state.customer);

  useEffect(() => {
    if (canvasRef.current) {
      BuildBarcode(canvasRef.current);
    }
  }, [canvasRef.current]);

  if (!CardNumber) {
    return null;
  }

  const BuildBarcode = (element) => {
    try {
      JsBarcode(element, `${CardNumber}`, {
        flat: true,
        format: 'upc',
        width: 2.5,
        height: 50,
        textMargin: 30,
        displayValue: false,
      });
    } catch (e) {
      //console.log("Barcode generation failed:", e);
    }
  };

  return (
    <div className={styles.tyrCardBarcode}>
      <canvas className={styles.tyrCardBarcodeCode} id="barcode" ref={canvasRef} />
      <span className={styles.tyrCardBarcodeNumber}>{CardNumber}</span>
    </div>
  );
};

TyrCardBarcode.propTypes = {
  CardNumber: PropTypes.string.isRequired,
};

export default TyrCardBarcode;
