// Libs
import * as PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';

// State
import crmService from '../../services/crmClientService';
import formValidators from '../../libs/form/form-validator';
// Deps
import FormInput from './form-input';
// Assets
import styles from './tyrCardInput.module.scss';
import locale from '../../locale/locale';
import { useStateValue } from '../../store/state';
import Tooltip from '@material-ui/core/Tooltip';
import { AppContext } from '../layout/AppLayout';
import getWindow from '../../libs/getWindow';

const TyrCardInput = ({
                        className,
                        label,
                        showCta,
                        disabled,
                        allowCurrentUserCardNum,
                        placeholder,
                        state: local,
                      }) => {
  const [state] = useStateValue();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const isApp = useContext(AppContext);

  const checkValidCard = debounce(() => {
    // If the current value matches the users TYR card number, assume valid already.
    // This is needed for the cart to now force API checks on the members existing card.
    if (
      local.value.get &&
      state.customer.CardNumber === local.value.get &&
      allowCurrentUserCardNum
    ) {
      local.valid.set(true);
      local.error.set(false);
    }
    // If the current value matches the users TYR card number, bit we don't want to allow that (Add/Replace cards), set invalid.
    // This is needed for the update or replace cards section.
    else if (
      local.value.get &&
      state.customer.CardNumber === local.value.get &&
      !allowCurrentUserCardNum
    ) {
      local.valid.set(false);
      local.error.set(locale.form.tyrNumber.error);
    }
    // Only fire against the API check if we have a value that passes the regex check.
    // console.log('TyrCardInput', state);
    else if (local.value.get && !local.error.get) {
      crmService
        .tyrCardValidate(local.value.get)
        .then((data) => {
          local.valid.set(true);
          local.error.set(false);
        })
        .catch((e) => {
          local.valid.set(false);
          if (e.ResponseCode === '301') {
            local.error.set(locale.form.tyrNumber.error);
          } else if (e.ResponseCode === '302') {
            local.error.set(locale.form.tyrNumber.errorCard);
          } else {
            local.error.set(true);
          }
        });
    }
    // If we're in some other state, assume the card is not valid.
    else {
      local.valid.set(false);
    }
  }, 500);

  useEffect(() => checkValidCard(), [local.value.get]);
  return (
    <div className={styles.tyrCartInput} data-label={!!label}>
      {showCta && (
        <div className={styles.tyrCartInputCta}>
          <div className={styles.tyrCartInputCtaButton}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setTooltipOpen(false)}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={locale.registrationPage.tooltips.tyr}
            >
              <span
                onClick={(e) => { isApp || (!!getWindow('innerWidth') && getWindow('innerWidth') < 768 ) ? setTooltipOpen(!tooltipOpen) : e.preventDefault() }}
                onMouseOver={(e) => { !isApp ? setTooltipOpen(true) : e.preventDefault() }}
                onFocus={(e) => { !isApp ? setTooltipOpen(true) : e.preventDefault() }}
                onMouseOut={(e) => { !isApp ? setTooltipOpen(false) : e.preventDefault() }}
                onBlur={(e) => { !isApp ? setTooltipOpen(false) : e.preventDefault() }}
              >
                {locale.form.whatsThis}
              </span>
            </Tooltip>
          </div>
        </div>
      )}
      <FormInput
        className={className}
        state={local}
        label={label}
        disabled={disabled}
        validator={formValidators.tyrNumberRaw}
        placeholder={placeholder || locale.form.tyrNumber.label}
        errorMsg={local.error.get === true ? locale.form.tyrNumber.error : local.error.get}
      />
    </div>
  );
};

TyrCardInput.defaultProps = {
  showCta: true,
  state: null,
  disabled: null,
  allowCurrentUserCardNum: true,
};

TyrCardInput.propTypes = {
  showCta: PropTypes.bool,
  state: PropTypes.shape({
    value: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    valid: PropTypes.object.isRequired,
  }).isRequired,
  allowCurrentUserCardNum: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TyrCardInput;
