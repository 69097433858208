// Libs
import React, { useContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
// Deps
import Navbar from './navbar';
import Footer from './footer';
// Assets
import styles from './member-layout.module.scss';
import CustomerAccountSidebar from '../customer/customer-sidebar';
import Modal from './modal';
import { useStateValue } from '../../store/state';
import { CloseModal } from '../../store/modal/action-creators';
import { uiBodyScrollable } from '../../store/ui/action-creators';
import useCustomer from '../../hooks/useCustomer';
import useInterval from '../../hooks/useInterval';
import CookieBanner from '../common/cookie-banner/cookie-banner';
// Services.
import crmClient from '../../services/crmClientService';
import pageAliases from '../../data/pageAliases'

import { AppContext } from './AppLayout';
import { siteOriginIs, siteTypes } from '../../libs/api';
import getWindow from '../../libs/getWindow';
import navigate from '../../libs/navigate';

export const ProfileMenuContext = React.createContext(false);

export const ProfileMenuBack = ({ title }) => {
  const [profileMenu, toggle] = useContext(ProfileMenuContext);
  return (
    <div
      className={styles.customerTurnBack}
      onClick={() => {
        toggle(!profileMenu);
      }}
    >
      {title}
    </div>
  );
};

const MemberLayout = ({ children, containered }) => {
  // remove modals from other pages.
  const [, dispatch] = useStateValue();
  const styleMain = containered ? styles.memberLayoutMainContainered : styles.memberLayoutMain;
  const [profileMenu, toggleUProfileMenu] = useState(false);
  const { getCustomerPoints } = useCustomer();
  const notify = typeof window !== 'undefined' ? window.notify : '';

  crmClient.useWebHeaders();

  useEffect(() => {
    dispatch(CloseModal());
    dispatch(uiBodyScrollable());
  }, []);

  useEffect(() => {
    window.dispatchEvent(new Event('notifychange'));
  }, [notify]);

  // Passively update customer points.
  useInterval(getCustomerPoints, 15000);

  // If the current site is an app site, kill main site layouts. Must check for bool, as -1 can mean this is SSR.
  if (siteOriginIs(siteTypes.app) === true && getWindow('location.pathname').indexOf('/policies') < 0) {
    navigate(pageAliases.appRewards);
    return null;
  }

  return (
    <>
      <ProfileMenuContext.Provider value={[profileMenu, toggleUProfileMenu]}>
        <Navbar/>
        <div className={styles.memberLayout}>
          <div className={styles.memberLayoutWrapperSidebar}></div>
          <main className={styleMain} data-active={profileMenu}>
            <div className={styles.memberLayoutMainSidebar}>
              <CustomerAccountSidebar ProfileMenuContext={ProfileMenuContext}/>
            </div>
            {containered ? (
              <div className={styles.memberLayoutMainContentContainered}>{children}</div>
            ) : (
              <div className={styles.memberLayoutMainContent}>{children}</div>
            )}
          </main>
          <CookieBanner />
          <Footer />
        </div>
        <Modal/>
      </ProfileMenuContext.Provider>
    </>
  );
};

MemberLayout.defaultProps = {
  children: null,
  containered: true,
};

MemberLayout.propTypes = {
  children: PropTypes.node.isRequired,
  containered: PropTypes.bool,
};

export default MemberLayout;
