import { useStaticQuery, graphql } from 'gatsby';
import { drupalTransformer } from '../libs/transform/index';

export const useContactUsQuery = () => {
  return drupalTransformer.transformNode(useContactUs());
};

const useContactUs = () => {
  return useStaticQuery(graphql`
      query useContactUsQuery {
          hero: configPagesContactUs {
              title: field_hero_title {
                  value
              }
              body: field_hero_description {
                  value
              }
          }
          directDetails: configPagesContactUs {
              relationships {
                  blocks: field_contact_us_direct_details {
                      title: field_type_label
                      line1: field_title {
                          value
                      }
                      line2: field_subtitle {
                          value
                      }
                  }
              }
          }
          additionalDetails: configPagesContactUs {
              blocks: field_additional_contact_details {
                  value
              }
          }
      }
  `);
};
