// Libs.
import React from 'react';
// Components.
import AppPrivacyPolicies from './app-privacy-policies';
import ContactUs from '../contact-us/contact-us';
// Store.
import { useStateValue } from '../../store/state';
import { OpenModal } from '../../store/modal/action-creators';
import { modalTypes } from '../../store/modal/action-types';
// Assets.
import styles from './app-corporate-links.module.scss';

const AppCorporateLinks = () => {
  const [, dispatch] = useStateValue();
  const openPrivacyPoliciesModal = () => {
    dispatch(
      OpenModal({
        id: 'privacy-policies',
        type: modalTypes.SLIDEIN,
        content: <AppPrivacyPolicies />,
      })
    );
  };

  const openContactUsModal = () => {
    dispatch(
      OpenModal({
        id: 'contact-us',
        type: modalTypes.SLIDEIN,
        content: <ContactUs />,
      })
    );
  };

  return (
    <ul className={styles.corporateLinksModalLinks}>
      <li>
        <button className={styles.corporateLinksModalLink} onClick={openContactUsModal}>Contact Us</button>
      </li>
      <li>
        <button className={styles.corporateLinksModalLink} onClick={openPrivacyPoliciesModal}>
          Privacy Policy
        </button>
      </li>
    </ul>
  );
};

export default AppCorporateLinks;