// Libs.
import React, { useContext, useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
// Components.
import TyrCardBarcode from './tyr-card-barcode';
import AppHidden from '../app/common/app-hidden';
import AppShown from '../app/common/app-shown';
// Services.
import crmService from '../../services/crmClientService';
// Hooks.
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Assets.
import Logo from '../../images/longos-logo.svg';
import addGooglePayIcon from '../../images/icons/icon-add-google-pay.svg';
import addAppleWalletIcon from '../../images/icons/icon-add-apple-wallet.svg';
import styles from './tyr-cardbox-mobile.module.scss';
// State.
import { useStateValue } from '../../store/state';
import { AppContext } from '../layout/AppLayout';
// Custom Libs.
import { addNotification } from '../../libs/notify';
import { getViewingPlatform } from '../../libs/getViewingPlatform';
// Loaders.
import ButtonLoader from '../common/loaders/button-loader';
import useInterval from '../../hooks/useInterval';

const TyrCardboxMobile = ({ customer }) => {
  const [state] = useStateValue();
  const imgCardMobile = useImageStaticQuery('try-card-mobile.png');
  const { CardNumber, CRMCustomerID } = customer;
  const { access_token } = state.user;
  const [isInstalling, setInstalling] = useState(false);
  const [isInstalled, setIsInstalled] = useState();
  const isApp = useContext(AppContext);
  const { iOS } = getViewingPlatform();
  const handleAddToPassbook = (e) => {
    // Prevent duplicated request when is installed / installing.
    if (isInstalled || isInstalling) {
      return;
    }

    setInstalling(true);
    const passAdaptiveLink = crmService
      .airshipPassAdaptiveLink(CRMCustomerID, access_token)
      .then((adaptiveLink) => {
        window.location = adaptiveLink;
        setInstalling(false);
      })
      .catch((e) => {
        addNotification(
          'Something failed adding the card to your wallet, please try again.',
          'error'
        );
        setInstalling(false);
      });
  };

  const checkPassbookInstall = () => {
    // Avoid checks on website.
    if (!isApp) return;
    crmService
      .airshipPassIsInstalled(CRMCustomerID, access_token)
      .then((status) => {
        setIsInstalled(status);
      })
      .catch((e) => {
        // Something failed determining pass installation, when installation
        // is unknown the add to wallet icons will be hidden.
        setIsInstalled(undefined);
      });
  };

  // Check installation state on initial render.
  useEffect(checkPassbookInstall, []);
  // Monitor installation state to catch state change.
  useInterval(checkPassbookInstall, 10000);

  return (
    <>
      <div className={styles.tyrCardboxMobileContainer} data-isapp={isApp}>
        <div className={styles.tyrCardboxMobileHeader}>
          <div>
            <img className={styles.tyrCardboxMobileLogo} src={Logo} alt="Longos" />
          </div>
          <div>
            <h3>Points</h3>
            <div>{customer.CardBalance}</div>
          </div>
        </div>
        <Img fluid={imgCardMobile.childImageSharp.fluid} />
        <AppHidden>
          <div className={styles.tyrCardboxMobileCardholder}>
            <h3>Member</h3>
            <div>
              {customer.FirstName} {customer.LastName}
            </div>
          </div>
        </AppHidden>
        {CardNumber && <TyrCardBarcode CardNumber={CardNumber} />}
      </div>
      <AppShown>
        {CardNumber && isInstalled === false && (
          <div className={styles.tyrCardboxMobilePassbook}>
            <button onClick={handleAddToPassbook} onTouchStart={handleAddToPassbook}>
              {isInstalling && <ButtonLoader />}
              {iOS && (
                <img
                  data-type="ios"
                  src={addAppleWalletIcon}
                  alt="Add rewards card to Apple Wallet"
                />
              )}
              {!iOS && (
                <img
                  data-type="android"
                  src={addGooglePayIcon}
                  alt="Add rewards card to Google Pay"
                />
              )}
            </button>
          </div>
        )}
      </AppShown>
    </>
  );
};

TyrCardboxMobile.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default TyrCardboxMobile;
