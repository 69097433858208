// Libs.
import React, { useContext } from 'react';
// Components.
import StaticContentList from '../common/static-content/static-content-list';
import ContactUsCards from './contact-us-cards';
import ContactUsForm from './contact-us-form';
import AppContactUsContent from '../app/app-contact-us-content';
import AppHidden from '../app/common/app-hidden';
import AppShown from '../app/common/app-shown';
// Custom Libs.
import { getContentSubItems } from '../../libs/static-content/static-content-utils';
import { redirectKeyPressHandler } from '../../libs/accessibility';
// State.
import { AppContext } from '../layout/AppLayout';
import { useStateValue } from '../../store/state';
import { CloseModal } from '../../store/modal/action-creators';
// Assets.
import styles from './contact-us.module.scss';
import locale from '../../locale/locale.json';
// import { getViewingPlatform } from '../../libs/getViewingPlatform';
import { useContactUsQuery } from '../../hooks/useContactUsQuery';
import {useFAQQuery} from "../../hooks/useFAQPageQuery";

const ContactUs = ({ close = true }) => {
  const { hero, ...pageContent } = useContactUsQuery();
  const isApp = useContext(AppContext);
  const [, dispatch] = useStateValue();

  const {configPagesFaq: {items: faqs} } = useFAQQuery();

  const closeModal = () => {
    dispatch(CloseModal());
  };

  // console.log('Contact Us', hero, pageContent);

  return (
    <div className={styles.contactUsMain} data-app={isApp}>
      <AppShown>
        <div
          role="button"
          tabIndex="0"
          onClick={closeModal}
          onKeyPress={redirectKeyPressHandler(closeModal)}
          className={styles.contactUsCloseIcon}
          data-app={isApp}
        />
      </AppShown>
      <div className={styles.contactUsBody}>
        <div className={styles.contactUsTitle}>{hero.title}</div>
        <div
          className={styles.contactUsSubtitle}
          dangerouslySetInnerHTML={{ __html: hero.body }}
        />
        <AppHidden>
          <ContactUsCards pageContent={pageContent}/>
        </AppHidden>
        <AppShown>
          <AppContactUsContent pageContent={pageContent}/>
        </AppShown>
        <AppHidden>
          <ContactUsForm/>
        </AppHidden>
      </div>
      <AppHidden>
        <div className={styles.contactUsWhiteBackground}>
          <StaticContentList
            listingTitle={locale.faqs.title}
            items={getContentSubItems(faqs, 'Customer Care', 3)}
            showViewAll={true}
            numItemsPerLine="3"
            viewAllLink="/faqs"
          />
        </div>
      </AppHidden>
      <AppShown>
        {close && <button
          onClick={closeModal}
          onKeyPress={redirectKeyPressHandler(closeModal)}
          className={styles.contactUsCloseButton}
        >
          Close
        </button>}
      </AppShown>
    </div>
  );
};

export default ContactUs;
