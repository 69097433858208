// Libs.
import React from 'react';
// Components.
import MemberLayout from '../components/layout/member-layout';
import CustomerTyrCards from '../components/customer/customer-tyr-cards';
// Custom Libs.
import { useAuthUserCheckOrError } from '../libs/handleHttpError';
import SEO from '../components/layout/seo';

const AccountTyrCardsPage = () => {
  useAuthUserCheckOrError();

  return (
    <MemberLayout>
      <SEO title="Account Cards" />
      <CustomerTyrCards />
    </MemberLayout>
  );
};

export default AccountTyrCardsPage;
