import React, { useLayoutEffect, useRef } from 'react';
import styles from './contact-white-card.module.scss';

const ContactWhiteCard = ({ card }) => {
  return (
    <div className={styles.contactWhiteCardContainer}>
      <div className={styles.contactWhiteCardTitle}>{card.title}</div>
      <div className={styles.contactWhiteCardLineOne} dangerouslySetInnerHTML={{ __html: card.line1 }}/>
      <div className={styles.contactWhiteCardLineTwo} dangerouslySetInnerHTML={{ __html: card.line2 }}/>
    </div>
  );
};
export default ContactWhiteCard;
