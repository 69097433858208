// Deps.
import React from 'react';
import Img from 'gatsby-image';
// Custom Hooks.
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Assets.
import styles from './download-mobapp-cta.module.scss';
import locale from '../../locale/locale';

// TODO: Should be deprecated and merged with longos-app-cta which preceded this component.
const DownloadMobappCTA = () => {
  const imgAppCtaAppStore = useImageStaticQuery('landing--get-the-longos--appstore.png');
  const imgAppCtaGooglePlay = useImageStaticQuery('landing--get-the-longos--googleplay.png');

  return (
    <div className={styles.downloadMobappCtaContainer}>
      <p>{locale.longosAppCta.summary}</p>
      <div className={styles.downloadMobappCtaButtons}>
        <a
          className={styles.downloadMobappCtaApplestore}
          href={locale.longosAppCta.appleLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img
            fluid={imgAppCtaAppStore.childImageSharp.fluid}
            alt={locale.longosAppCta.getAppFromAppStore}
          />
        </a>
        <a
          className={styles.downloadMobappCtaPlaystore}
          href={locale.longosAppCta.googleLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img
            fluid={imgAppCtaGooglePlay.childImageSharp.fluid}
            alt={locale.longosAppCta.getAppFromPlayStore}
          />
        </a>
      </div>
    </div>
  );
};

export default DownloadMobappCTA;
