/* eslint-disable no-unused-vars */
// Libs.
import React, { useContext } from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
// Components.
import AppCorporateLinks from '../app/app-corporate-links';
// State.
import { useStateValue } from '../../store/state';
// Assets.
import styles from './customer-sidebar.module.scss';
import pageAliases from '../../data/pageAliases';
import locale from '../../locale/locale';
import { completeLogout } from '../../store/user/actions';
import crmService from '../../services/crmClientService';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import AppShown from '../app/common/app-shown';
import AppHidden from '../app/common/app-hidden';
import { useDrawer } from '../../hooks/useNavigationDrawer';
import { AppContext } from '../layout/AppLayout';
import navigate from '../../libs/navigate';

const CustomerAccountSidebar = ({ ProfileMenuContext, drawer }) => {
  const app = useContext(AppContext);
  const [, navigateDrawer] = useDrawer([]);
  const [state, dispatch] = useStateValue();
  const [, toggle] = useContext(ProfileMenuContext);
  if (!state.user.access_token) {
    return null;
  }
  const isProfileActive = ({ location }) => {
    const profilePath = '/account-profile';
    return location.pathname.includes(profilePath)
      ? { className: styles.accountSidebarActiveLink }
      : null;
  };
  const isHistoryActive = ({ location }) => {
    const profilePath = '/account-order-history';
    return location.pathname.includes(profilePath)
      ? { className: styles.accountSidebarActiveLink }
      : null;
  };

  // Handle which route to serve users too upon logout based on app context.
  const logoutPath = () => {
    app ? navigate('/app/rewards/') : navigate('/');
  };

  const logoutUser = () => {
    // TODO: As dispatch functions are called here, the promise resolve should
    // not re-execute due use effects reacting on access_token user state prop
    // change react but this re-execution inside the promise will override any
    // previous effect causing that defaultStoreId after logout transition
    // handles incorrectly, when the completeLogout is reactivated inside the
    // promise resolution this needs to be removed.
    // TODO: This does nothing as of yet, so don't wait to log the user out.
    logoutPath();
    completeLogout(dispatch);
    crmService
      .userLogout(state.user.CRMCustomerID, state.user.access_token)
      .then((data) => {
        // setTimeout(() => {
        //   completeLogout(dispatch);
        //   logoutPath();
        // }, 100);
        // completeLogout(dispatch);
      })
      .catch((error) => {
        // No errors to handle yet, this isn't fully supported by CRM.
        //console.log(error);
        // completeLogout(dispatch);
        // logoutPath();
        // setTimeout(() => completeLogout(dispatch), 100);
      });
  };

  return (
    <div className={styles.accountSidebarWrapper} data-app={!!app}>
      <div className={styles.accountSidebar} data-app={!!app}>
        <div className={styles.accountSidebarWelcome}>
          <>
            <AppShown>Hi, {state.customer.FirstName}!</AppShown>
            <AppHidden>Welcome back, {state.customer.FirstName}!</AppHidden>
          </>
        </div>
        {state.customer.CardBalance && (
          <div className={styles.accountSidebarPoints}>{state.customer.CardBalance} Points</div>
        )}
        <AppHidden>
          <div className={styles.accountSidebarAccount}>My Account</div>
        </AppHidden>
        <div className={styles.accountSidebarMenu}>
          {state.customer.CardNumber && (
            <div>
              <AppShown>
                <div
                  className={styles.accountSidebarActiveDiv}
                  onClick={() => {
                    navigateDrawer('points');
                  }}
                >
                  {locale.usermenu.points}
                </div>
              </AppShown>
              <AppHidden>
                <Link
                  to={pageAliases.accountPoints}
                  onClick={() => toggle(false)}
                  activeClassName={styles.accountSidebarActiveLink}
                >
                  {locale.usermenu.points}
                </Link>
              </AppHidden>
            </div>
          )}
          <div>
            <AppShown>
              <div
                className={styles.accountSidebarActiveDiv}
                onClick={() => {
                  navigateDrawer(state.customer.CardNumber ? 'cards' : 'addCard');
                }}
              >
                {locale.usermenu.cards}
              </div>
            </AppShown>
            <AppHidden>
              <Link
                to={pageAliases.accountCards}
                onClick={() => toggle(false)}
                activeClassName={styles.accountSidebarActiveLink}
              >
                {locale.usermenu.cards}
              </Link>
            </AppHidden>
          </div>
          <div>
            <AppShown>
              <div
                className={styles.accountSidebarActiveDiv}
                onClick={() => {
                  navigateDrawer('personalInfo');
                }}
              >
                {locale.usermenu.personalInfo}
              </div>
            </AppShown>
            <AppHidden>
              <Link
                to={pageAliases.accountProfile}
                onClick={() => toggle(false)}
                activeClassName={styles.accountSidebarActiveLink}
                getProps={isProfileActive}
              >
                {locale.usermenu.personalInfo}
              </Link>
            </AppHidden>
          </div>
          <div>
            <AppShown>
              <div
                className={styles.accountSidebarActiveDiv}
                onClick={() => {
                  navigateDrawer('transactionHistory_classes');
                }}
              >
                {locale.usermenu.transactionHistory}
              </div>
            </AppShown>
            <AppHidden>
              <Link
                to={pageAliases.groceriesTransactionList}
                onClick={() => toggle(false)}
                activeClassName={styles.accountSidebarActiveLink}
                getProps={isHistoryActive}
              >
                {locale.usermenu.transactionHistory}
              </Link>
            </AppHidden>
          </div>
        </div>
        <button
          className={styles.accountSidebarLogout}
          onClick={logoutUser}
          onKeyPress={redirectKeyPressHandler(logoutUser)}
        >
          Sign out
        </button>
        <AppShown>
          <AppCorporateLinks />
        </AppShown>
      </div>
    </div>
  );
};

CustomerAccountSidebar.propTypes = {
  ProfileMenuContext: PropTypes.object,
};

export default CustomerAccountSidebar;
