import { useStaticQuery, graphql } from 'gatsby';
import {drupalTransformer} from '../libs/transform/index'

export const usePoliciesQuery = () => {
  return drupalTransformer.transformNode(usePoliciesPage());
};

const usePoliciesPage = () => {
  return useStaticQuery(graphql`
    query PolicyApp {
      configPagesPoliciesApp {
        title:field_app_title
        subtitle:field_quote {
          value
        }
        body:field_loft_hero_body {
          value
        }
      }
    }
  `);
};
