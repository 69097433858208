// Libs.
import React from 'react';
import { navigate } from 'gatsby';
// Assets.
import styles from './app-contact-us-content.module.scss';
import { contactUs } from '../../locale/locale.json';
import appAliases from '../../data/appAliases.json';

const AppContactUsContent = ({ pageContent: { additionalDetails = [], directDetails = [] } = {} }) => {
  const { whiteCards, brownCards } = contactUs;
  const goToStores = () => {
    navigate(appAliases.appStores);
  };

  return (
    <div className={styles.contactUsContentContainer}>
      <div className={styles.contactUsContentContactSection}>
        <div>
          <div className={styles.contactUsContentCardTitle}>{whiteCards[0].title}</div>
          <div className={styles.contactUsContentPhone}>
            <a href="tel:18009566467">{whiteCards[0].line1}</a>
          </div>
        </div>
        <div>
          <div className={styles.contactUsContentCardTitle}>{whiteCards[2].title}</div>
          <div
            className={styles.contactUsContentCardLineOne}
            dangerouslySetInnerHTML={{ __html: whiteCards[2].line1 }}
          />
        </div>
        <div>
          <div className={styles.contactUsContentCardTitle}>{whiteCards[1].title}</div>
          <div
            className={styles.contactUsContentCardLineOne}
            dangerouslySetInnerHTML={{ __html: whiteCards[1].line1 }}
          />
          <div
            className={styles.contactUsContentCardLineTwo}
            dangerouslySetInnerHTML={{ __html: whiteCards[1].line2 }}
          />
        </div>
      </div>
      <div className={styles.contactUsContentScheduleSection}>
        <div>
          <div dangerouslySetInnerHTML={{ __html: brownCards[0].line1 }} />
          <div dangerouslySetInnerHTML={{ __html: brownCards[0].line2 }} />
        </div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: brownCards[1].line1 }} />
          <button onClick={goToStores} className={styles.contactUsContentFindStore}>
            Find a store
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppContactUsContent;
