import React from 'react';
import styles from './contact-us-cards.module.scss';
import { contactUs } from '../../locale/locale.json';

import ContactWhiteCard from './contact-white-card';
import ContactBrownCard from './contact-brown-card';

const ContactUsCards = ({ pageContent: { additionalDetails = [], directDetails = [] } = {} }) => {
  return (
    <div className={styles.contactUsCardsMainContainer}>
      <div className={styles.contactUsCardsContainerWhite}>
        {
          directDetails.blocks.map((card, index) => <ContactWhiteCard key={`whiteCard_${index}`} card={card}/>)
        }
      </div>
      <div className={styles.contactUsCardsContainerBrown}>
        {
          additionalDetails.blocks.map((card, index) => <ContactBrownCard key={`brownCard_${index}`} card={card}/>)
        }
      </div>
    </div>
  );
};

export default ContactUsCards;
