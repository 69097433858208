import React, {useContext, useState} from 'react';
// Components.
import TyrCardInput from '../common/tyrCardInput';
import TyrCardPinInput, { TYR_CARD_FOR_PIN } from '../common/tyrCardPinInput';
// State.
import { useStateValue } from '../../store/state';
import { CloseModal } from '../../store/modal/action-creators';
import { uiBodyScrollable } from '../../store/ui/action-creators';
// Store.
import { AddCardNumber, ReplaceCardNumber, FetchCardLoad } from '../../store/customer/actions';
// Assets.
import styles from './customer-add-replace-cards.module.scss';
import { addReplaceCard as locale } from '../../locale/locale.json';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import {AppContext} from "../layout/AppLayout";
import {useDrawer} from "../../hooks/useNavigationDrawer";
import navigate from '../../libs/navigate';
import appAliases from '../../data/appAliases';
import ButtonLoader from '../common/loaders/button-loader';

const AddReplaceCards = ({ action, notDrawer = true }) => {
  const [state, dispatch] = useStateValue();
  const { user, customer } = state;
  const app = useContext(AppContext);
  const [, closeDrawer] = useDrawer();
  const [tyrNumber, setTyrNumber] = useState('');
  const [tyrError, setTyrError] = useState(false);
  const [tyrValid, setTyrValid] = useState(false);
  const [tyrPinNumber, setTyrPinNumber] = useState('');
  const [tyrPinError, setTyrPinError] = useState(false);
  const [tyrPinValid, setTyrPinValid] = useState(false);
  const [loading, isLoading] = useState(false);
  const capAction = action.charAt(0).toUpperCase() + action.slice(1);

  const closeModal = () => {
    // Set active if callback was provided.
    dispatch(CloseModal());
    dispatch(uiBodyScrollable());
  };

  const onCardNumberSubmit = () => {
    if (!tyrValid || tyrNumber === '') {
      return;
    }
    if (parseInt(tyrNumber.trim()) > TYR_CARD_FOR_PIN && !tyrPinValid) {
      return;
    }
    isLoading(true);
    let response;
    let cardData = {
        CardNumber: tyrNumber,
    };
    if(parseInt(tyrNumber.trim()) > TYR_CARD_FOR_PIN && tyrPinNumber && tyrPinValid) {
      cardData.CardPIN = tyrPinNumber;
    }
    if (action === 'add') {
      response = AddCardNumber(user.CRMCustomerID, tyrNumber, cardData, user.access_token, dispatch);
    } else if (action === 'replace') {
      response = ReplaceCardNumber(user.CRMCustomerID, tyrNumber, cardData, user.access_token, dispatch);
    }
    response
      .then((res) => {
        FetchCardLoad(user.CRMCustomerID, user.access_token, dispatch).catch((e) => {
          closeDrawer(false, false);
        });
        isLoading(false);
        if (!notDrawer && customer.tyrCard) {
          closeDrawer('cards', true);
        }
        else {
          closeModal();
          if (app) {
            navigate(`${appAliases.appAccountProfile}?drawer=cards`);
          }
        }
      })
      .catch((err) => {
        isLoading(false);
        if (err.ResponseCode === '303') {
          setTyrPinError(true);
        }
      });
  };

  return (
    <div className={styles.customerAddReplaceCardsOverlay} data-app={app}>
      <div className={styles.customerAddReplaceCardsContainer}>
        <div className={styles.customerAddReplaceCardsContainerCentered}>
          {notDrawer && <span
            role="button"
            tabIndex="0"
            onClick={closeModal}
            onKeyPress={redirectKeyPressHandler(closeModal)}
            className={styles.customerAddReplaceCardsClose}
          />}
          <h1 className={styles.customerAddReplaceCardsTitle}>
            {locale.titles[`primary${capAction}`]}
          </h1>
          <p className={styles.customerAddReplaceCardsSubtitle}>
            {app ? locale.messages.appSubtitleAdd : locale.messages[`subtitle${capAction}`]}
          </p>
          <div className={styles.customerAddReplaceCardsInputTitle}>
            {locale.messages[`cardInputTitle${capAction}`]}
          </div>
          <TyrCardInput
            placeholder=" "
            className={styles.customerAddReplaceCardsInputField}
            state={{
              value: { get: tyrNumber, set: setTyrNumber },
              error: { get: tyrError, set: setTyrError },
              valid: { get: tyrValid, set: setTyrValid },
            }}
            showCta={false}
            allowCurrentUserCardNum={false}
          />
          <div className={`${styles.customerAddReplaceCardsInputTitle} ${styles.withLabel}`}>
            {locale.messages[`cardPinInputTitle`]}
          </div>
          <TyrCardPinInput
            placeholder=" "
            containerClassName={styles.customerAddReplaceCardsPinContainer}
            className={styles.customerAddReplaceCardsInputField}
            state={{
              cardNumber: { get: tyrNumber, set: setTyrNumber },
              cardNumberValid: { get: tyrValid, set: setTyrNumber },
              value: { get: tyrPinNumber, set: setTyrPinNumber },
              error: { get: tyrPinError, set: setTyrPinError },
              valid: { get: tyrPinValid, set: setTyrPinValid },
            }}
            showCta={true}
            showLabel={true}
            ctaPosition="left"
          />
          <div className={styles.customerAddReplaceCardsActions}>
            <button
              onClick={() => {
                onCardNumberSubmit();
              }}
              className={styles.customerAddReplaceCardsButtonConfirm}
              disabled={!tyrValid || tyrNumber === '' || tyrPinError}
            >
              {locale.buttons.confirm}
              {loading && <ButtonLoader />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReplaceCards;
