// @flow
import { useStateValue } from '../store/state';
import crmService from '../services/crmClientService';
import type { ContactCaseCreate } from '../services/flow-typed/crmClientServiceTypes';

/**
 * Route contact case create to guest/customer CRM endpoint call.
 *
 * Handle the state checks that determine when guest / customer CRM method
 * should be called and returns callable function routed to appropriate CRM
 * subscription method.
 *
 * @example
 * const contactCaseCreate = useContactCaseRouter();
 * const contactCase = {
 *   Name: 'Test Name',
 *   EmailAddress: 'testing.webapi.01@gmail.com',
 *   Phone: '6471234567',
 *   Message: 'Test contact case submission to CRM.',
 * };
 * contactCaseCreate(contactCase)
 *   .then((status) => {
 *     // Create success, handle post create logic.
 *   })
 *   .catch((error) => {
 *     console.log('Create error: ', error);
 *     // Create failed, handle errors logic.
 *   });
 *
 * @returns {function} - Callable function to create contact case.
 */
export function useContactCaseRouter() {
  const [state] = useStateValue();
  const { access_token, CRMCustomerID } = state.user;

  // Callable function requires contactCase payload following ContactCaseCreate
  // structure type to pass to CRM method.
  return (contactCase: ContactCaseCreate) => {
    if (CRMCustomerID && access_token) {
      return crmService.customerContactCaseCreate(CRMCustomerID, contactCase, access_token);
    } else {
      return crmService.guestContactCaseCreate(contactCase.EmailAddress, contactCase);
    }
  };
}
