import React, { useState } from 'react';
import { contactUs } from '../../locale/locale.json';

import FormInput from '../common/form-input';
import formValidators from '../../libs/form/form-validator';

import styles from './contact-us-form.module.scss';
import { useContactCaseRouter } from '../../hooks/useContactCaseRouter';
import { useStateValue } from '../../store/state';
import ButtonLoader from '../common/loaders/button-loader';
import PagePreloader from '../common/loaders/page-preloader';
import awaitHandler from '../../libs/asyncHandler';
import crmService from '../../services/crmClientService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { pushEvent } from '../../libs/dataLayer';

const ContactUsForm = () => {
  const [state] = useStateValue();
  const { customer } = state;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [name, setName] = useState({
    value: customer.CRMCustomerID !== '' ? `${customer.FirstName} ${customer.LastName}` : '',
    error: false,
  });
  const [email, setEmail] = useState({ value: customer.EmailAddress, error: false });
  const [phone, setPhone] = useState({ value: customer.PrimaryPhone, error: false });
  const [message, setMessage] = useState({ value: '', error: false });
  const [submission, setSubmission] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');

  const contactCaseCreate = useContactCaseRouter();

  const validateForm = async () => {
    // Never allow resubmissions while in progress.
    if (submission) {
      return;
    }

    // Start loader
    setSubmission(true);
    // With await, attempt to submit the form is valid.
    if (!email.error && !phone.error) {
      if (name.value !== '' && email.value !== '' && message.value !== '') {
        // submit
        const contactCase = {
          Name: name.value,
          EmailAddress: email.value,
          Phone: phone.value,
          Message: message.value,
        };

        pushEvent('interaction', 'ContactUs Form submit', contactCase);
        // Attempt submission.
        const [submitRes, submitErr] = await awaitHandler(contactCaseCreate(contactCase));

        if (submitRes) {
          // On success, notify the user.
          setFormSubmitted(true);
          setSubmissionMessage(contactUs.contactForm.successMessage);

          // After a timed release upon success, restore the form.
          setTimeout(() => {
            setMessage({ ...message, value: '' });
            setSubmissionMessage('');
            setFormSubmitted(false);
          }, 15 * 1000);
        } else if (submitErr) {
          const token = await executeRecaptcha('contactUs');
          crmService.setRecaptchaToken(token);
          setSubmissionMessage(contactUs.contactForm.generalErrorMessage);
        }
      } else {
        setName({ value: name.value, error: name.value === '' });
        setEmail({ value: email.value, error: email.value === '' });
        setMessage({ value: message.value, error: message.value === '' });
      }
    }
    // Finally, release the loader.
    setSubmission(false);
  };

  return (
    <div className={styles.contactUsFormMainContainer}>
      <div className={styles.contactUsFormHeaderContainer}>
        <h2 className={styles.contactUsFormTitle}>{contactUs.contactForm.title}</h2>
      </div>
      <div className={styles.contactUsFormInputContainer} data-submitted={formSubmitted}>
        {formSubmitted && (
          <>
            <div className={styles.contactUsFormSuccessMessage}>{submissionMessage}</div>
          </>
        )}
        {!formSubmitted && (
          <>
            <div style={{ position: 'relative' }}>
              {submission && <PagePreloader/>}
              <div className={styles.contactUsFormErrorMessage}>{submissionMessage}</div>
              <FormInput
                placeholder={contactUs.contactForm.namePlaceholder}
                value={name.value}
                onChange={(e) => formValidators.exists(e.target.value, name, setName)}
                error={name.error}
                errorMsg={name.error && contactUs.contactForm.nameError}
              />

              <FormInput
                placeholder={contactUs.contactForm.emailPlaceholder}
                value={email.value}
                onChange={(e) => formValidators.email(e.target.value, email, setEmail)}
                error={email.error}
                errorMsg={email.error && contactUs.contactForm.emailError}
              />

              <FormInput
                placeholder={contactUs.contactForm.phonePlaceholder}
                value={phone.value}
                onChange={(e) => formValidators.phone(e.target.value, phone, setPhone)}
                error={phone.error}
                errorMsg={phone.error && contactUs.contactForm.phoneError}
              />
              <textarea
                rows="10"
                placeholder={contactUs.contactForm.messagePlaceholder}
                aria-label={contactUs.contactForm.messagePlaceholder}
                onChange={(e) => formValidators.exists(e.target.value, message, setMessage)}
                value={message.value}
              />
              {message.error && (
                <div className={styles.contactUsFormErrorMessage}>
                  {contactUs.contactForm.messageError}
                </div>
              )}
              <button
                type="submit"
                onClick={() => {
                  validateForm();
                }}
              >
                {contactUs.contactForm.submitBtnLabel}
                {submission && <ButtonLoader/>}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactUsForm;
