// Deps.
import React from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
// Hooks.z
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
// Assets
import styles from './tyr-cardbox-desktop.module.scss';

const TyrCardboxDesktop = ({ customer }) => {
  const imgCardDesktop = useImageStaticQuery('tyr-card-desktop.png');

  // console.log('imgCardDesktop', imgCardDesktop);

  return (
    <div className={styles.tyrCardboxDesktopContainer}>
      <Img fluid={imgCardDesktop.childImageSharp.fluid}/>
      <div className={styles.tyrCardboxDesktopCardholder}>
        {customer.FirstName} {customer.LastName}
      </div>
      <div className={styles.tyrCardboxDesktopCardnumber}>{customer.CardNumber}</div>
    </div>
  );
};

TyrCardboxDesktop.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default TyrCardboxDesktop;
